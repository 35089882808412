.whatsapp {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 600;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.whatsapp .block {
  display: block;
  height: 60px;
  width: 60px;
  text-align: center;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 15px 6px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 6px rgb(0 0 0 / 10%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 32px;
  color: #ffffff;
  background: #25d366;
}

.whatsapp .block:hover {
  background: #00ff5e;
}
